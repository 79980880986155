var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ck-text-input", {
        attrs: {
          value: _vm.name,
          id: "name",
          label: "Name of location",
          type: "text",
          error: _vm.errors.get("name")
        },
        on: {
          input: function($event) {
            return _vm.onInput({ field: "name", value: $event })
          }
        }
      }),
      _vm.isCreateForm
        ? [
            _c("ck-radio-input", {
              attrs: {
                value: _vm.location_type,
                id: "location_type",
                label: "Select location",
                hint:
                  "You can select an existing location or create a new one.",
                options: _vm.locationTypes,
                error: null
              },
              on: {
                input: function($event) {
                  return _vm.onInput({ field: "location_type", value: $event })
                }
              }
            }),
            _vm.location_type === "existing"
              ? _c(
                  "gov-inset-text",
                  [
                    _vm.loading
                      ? _c("ck-loader")
                      : _c("ck-select-input", {
                          attrs: {
                            value: _vm.location_id,
                            id: "location_id",
                            label: "Location",
                            options: _vm.locations,
                            error: _vm.errors.get("location_id")
                          },
                          on: {
                            input: function($event) {
                              return _vm.onInput({
                                field: "location_id",
                                value: $event
                              })
                            }
                          }
                        })
                  ],
                  1
                )
              : _vm.location_type === "new"
              ? _c(
                  "gov-inset-text",
                  [
                    _c("location-form", {
                      attrs: {
                        errors: _vm.locationErrors,
                        address_line_1: _vm.address_line_1,
                        address_line_2: _vm.address_line_2,
                        address_line_3: _vm.address_line_3,
                        city: _vm.city,
                        county: _vm.county,
                        postcode: _vm.postcode,
                        country: _vm.country,
                        has_induction_loop: _vm.has_induction_loop,
                        has_wheelchair_access: _vm.has_wheelchair_access,
                        has_accessible_toilet: _vm.has_accessible_toilet
                      },
                      on: {
                        "update:address_line_1": function($event) {
                          return _vm.onLocationInput({
                            field: "address_line_1",
                            value: $event
                          })
                        },
                        "update:address_line_2": function($event) {
                          return _vm.onLocationInput({
                            field: "address_line_2",
                            value: $event
                          })
                        },
                        "update:address_line_3": function($event) {
                          return _vm.onLocationInput({
                            field: "address_line_3",
                            value: $event
                          })
                        },
                        "update:city": function($event) {
                          return _vm.onLocationInput({
                            field: "city",
                            value: $event
                          })
                        },
                        "update:county": function($event) {
                          return _vm.onLocationInput({
                            field: "county",
                            value: $event
                          })
                        },
                        "update:postcode": function($event) {
                          return _vm.onLocationInput({
                            field: "postcode",
                            value: $event
                          })
                        },
                        "update:country": function($event) {
                          return _vm.onLocationInput({
                            field: "country",
                            value: $event
                          })
                        },
                        "update:has_induction_loop": function($event) {
                          return _vm.onLocationInput({
                            field: "has_induction_loop",
                            value: $event
                          })
                        },
                        "update:has_wheelchair_access": function($event) {
                          return _vm.onLocationInput({
                            field: "has_wheelchair_access",
                            value: $event
                          })
                        },
                        clear: function($event) {
                          return _vm.$emit("clear-location", $event)
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]
        : _vm._e(),
      _c("gov-heading", { attrs: { size: "m" } }, [_vm._v("Opening hours")]),
      _vm._l(_vm.regular_opening_hours, function(regularOpeningHour, index) {
        return _c(
          "gov-inset-text",
          { key: regularOpeningHour.index },
          [
            _c("ck-select-input", {
              attrs: {
                value: regularOpeningHour.frequency,
                id: "regular_opening_hours." + index + ".frequency",
                label: "Frequency type",
                options: _vm.frequencies,
                error: _vm.errors.get(
                  "regular_opening_hours." + index + ".frequency"
                )
              },
              on: {
                input: function($event) {
                  return _vm.onRegularOpeningHourInput({
                    index: index,
                    field: "frequency",
                    value: $event
                  })
                }
              }
            }),
            ["weekly", "nth_occurrence_of_month"].includes(
              regularOpeningHour.frequency
            )
              ? _c("ck-select-input", {
                  attrs: {
                    value: regularOpeningHour.weekday,
                    id: "regular_opening_hours." + index + ".weekday",
                    label: "Weekday",
                    options: _vm.weekdays,
                    error: _vm.errors.get(
                      "regular_opening_hours." + index + ".weekday"
                    )
                  },
                  on: {
                    input: function($event) {
                      return _vm.onRegularOpeningHourInput({
                        index: index,
                        field: "weekday",
                        value: $event
                      })
                    }
                  }
                })
              : _vm._e(),
            regularOpeningHour.frequency === "monthly"
              ? _c("ck-select-input", {
                  attrs: {
                    value: regularOpeningHour.day_of_month,
                    id: "regular_opening_hours." + index + ".day_of_month",
                    label: "Day of month",
                    options: _vm.daysInMonth,
                    error: _vm.errors.get(
                      "regular_opening_hours." + index + ".day_of_month"
                    )
                  },
                  on: {
                    input: function($event) {
                      return _vm.onRegularOpeningHourInput({
                        index: index,
                        field: "day_of_month",
                        value: $event
                      })
                    }
                  }
                })
              : _vm._e(),
            regularOpeningHour.frequency === "nth_occurrence_of_month"
              ? _c("ck-select-input", {
                  attrs: {
                    value: regularOpeningHour.occurrence_of_month,
                    id:
                      "regular_opening_hours." + index + ".occurrence_of_month",
                    label: "Occurrence in month",
                    options: _vm.occurrences,
                    error: _vm.errors.get(
                      "regular_opening_hours." + index + ".occurrence_of_month"
                    )
                  },
                  on: {
                    input: function($event) {
                      return _vm.onRegularOpeningHourInput({
                        index: index,
                        field: "occurrence_of_month",
                        value: $event
                      })
                    }
                  }
                })
              : _vm._e(),
            regularOpeningHour.frequency === "fortnightly"
              ? _c("date-input", {
                  attrs: {
                    value: regularOpeningHour.starts_at,
                    id: "regular_opening_hours." + index + ".starts_at",
                    label: "Starting date",
                    error: _vm.errors.get(
                      "regular_opening_hours." + index + ".starts_at"
                    )
                  },
                  on: {
                    input: function($event) {
                      return _vm.onRegularOpeningHourInput({
                        index: index,
                        field: "starts_at",
                        value: $event
                      })
                    }
                  }
                })
              : _vm._e(),
            _c("time-period-input", {
              attrs: {
                id: "regular_opening_hours." + index,
                opens_at: regularOpeningHour.opens_at,
                closes_at: regularOpeningHour.closes_at,
                opens_at_label: "Opening time",
                closes_at_label: "Closing time",
                error: _vm.errors.get([
                  "regular_opening_hours." + index + ".opens_at",
                  "regular_opening_hours." + index + ".closes_at"
                ])
              },
              on: {
                "update:opens_at": function($event) {
                  return _vm.onRegularOpeningHourInput({
                    index: index,
                    field: "opens_at",
                    value: $event
                  })
                },
                "update:closes_at": function($event) {
                  return _vm.onRegularOpeningHourInput({
                    index: index,
                    field: "closes_at",
                    value: $event
                  })
                }
              }
            }),
            _c(
              "gov-button",
              {
                attrs: { error: "" },
                on: {
                  click: function($event) {
                    return _vm.onDeleteRegularOpeningHour(index)
                  }
                }
              },
              [_vm._v("Delete day")]
            )
          ],
          1
        )
      }),
      _c(
        "gov-button",
        {
          on: {
            click: function($event) {
              return _vm.onAddRegularOpeningHour(_vm.index)
            }
          }
        },
        [
          _vm.regular_opening_hours.length === 0
            ? [_vm._v("Add day")]
            : [_vm._v("Add another day")]
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "gov-button",
        {
          on: {
            click: function($event) {
              return _vm.onAddWeekdays()
            }
          }
        },
        [_vm._v(" Add weekdays ")]
      ),
      _c("gov-heading", { attrs: { size: "m" } }, [_vm._v("Holiday times")]),
      _vm._l(_vm.holiday_opening_hours, function(holidayOpeningHour, index) {
        return _c(
          "gov-inset-text",
          { key: holidayOpeningHour.index },
          [
            _c("is-closed-input", {
              attrs: {
                value: holidayOpeningHour.is_closed,
                id: "holiday_opening_hours." + index + ".is_closed",
                error: _vm.errors.get(
                  "holiday_opening_hours." + index + ".is_closed"
                )
              },
              on: {
                input: function($event) {
                  return _vm.onHolidayOpeningHourInput({
                    index: index,
                    field: "is_closed",
                    value: $event
                  })
                }
              }
            }),
            _c("date-input", {
              attrs: {
                value: holidayOpeningHour.starts_at,
                id: "holiday_opening_hours." + index + ".starts_at",
                label: "Starting date",
                error: _vm.errors.get(
                  "holiday_opening_hours." + index + ".starts_at"
                )
              },
              on: {
                input: function($event) {
                  return _vm.onHolidayOpeningHourInput({
                    index: index,
                    field: "starts_at",
                    value: $event
                  })
                }
              }
            }),
            _c("date-input", {
              attrs: {
                value: holidayOpeningHour.ends_at,
                id: "holiday_opening_hours." + index + ".ends_at",
                label: "Ending date",
                error: _vm.errors.get(
                  "holiday_opening_hours." + index + ".ends_at"
                )
              },
              on: {
                input: function($event) {
                  return _vm.onHolidayOpeningHourInput({
                    index: index,
                    field: "ends_at",
                    value: $event
                  })
                }
              }
            }),
            holidayOpeningHour.is_closed === false
              ? _c("time-period-input", {
                  attrs: {
                    id: "holiday_opening_hours." + index,
                    opens_at: holidayOpeningHour.opens_at,
                    closes_at: holidayOpeningHour.closes_at,
                    error: _vm.errors.get([
                      "holiday_opening_hours." + index + ".opens_at",
                      "holiday_opening_hours." + index + ".closes_at"
                    ])
                  },
                  on: {
                    "update:opens_at": function($event) {
                      return _vm.onHolidayOpeningHourInput({
                        index: index,
                        field: "opens_at",
                        value: $event
                      })
                    },
                    "update:closes_at": function($event) {
                      return _vm.onHolidayOpeningHourInput({
                        index: index,
                        field: "closes_at",
                        value: $event
                      })
                    }
                  }
                })
              : _vm._e(),
            _c(
              "gov-button",
              {
                attrs: { error: "" },
                on: {
                  click: function($event) {
                    return _vm.onDeleteHolidayOpeningHour(index)
                  }
                }
              },
              [_vm._v("Delete holiday times")]
            )
          ],
          1
        )
      }),
      _c(
        "gov-button",
        {
          on: {
            click: function($event) {
              return _vm.onAddHolidayOpeningHour(_vm.index)
            }
          }
        },
        [
          _vm.holiday_opening_hours.length === 0
            ? [_vm._v("Add holiday times")]
            : [_vm._v("Add more holiday times")]
        ],
        2
      ),
      _c("ck-image-input", {
        attrs: {
          id: "image",
          label: "Service location image",
          "existing-url": _vm.id
            ? _vm.apiUrl(
                "/service-locations/" + _vm.id + "/image.png?v=" + _vm.now
              )
            : undefined
        },
        on: {
          input: function($event) {
            return _vm.onInput({
              field: "image_file_id",
              value: $event.file_id
            })
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }