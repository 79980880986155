var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-form-group",
    { attrs: { invalid: _vm.error !== null } },
    [
      _c("gov-label", {
        attrs: { for: _vm.id },
        domProps: { textContent: _vm._s(_vm.label) }
      }),
      _c("gov-input", {
        attrs: {
          id: _vm.id + "_day",
          name: _vm.id + "_day",
          type: "number",
          width: 2,
          placeholder: "DD"
        },
        on: { input: _vm.onInput },
        model: {
          value: _vm.day,
          callback: function($$v) {
            _vm.day = $$v
          },
          expression: "day"
        }
      }),
      _vm._v(" "),
      _c("gov-input", {
        attrs: {
          id: _vm.id + "_month",
          name: _vm.id + "_month",
          type: "number",
          width: 2,
          placeholder: "MM"
        },
        on: { input: _vm.onInput },
        model: {
          value: _vm.month,
          callback: function($$v) {
            _vm.month = $$v
          },
          expression: "month"
        }
      }),
      _vm._v(" "),
      _c("gov-input", {
        attrs: {
          id: _vm.id + "_year",
          name: _vm.id + "_year",
          type: "number",
          width: 4,
          placeholder: "YYYYY"
        },
        on: { input: _vm.onInput },
        model: {
          value: _vm.year,
          callback: function($$v) {
            _vm.year = $$v
          },
          expression: "year"
        }
      }),
      _vm.error
        ? _c("gov-error-message", {
            attrs: { for: _vm.id },
            domProps: { textContent: _vm._s(_vm.error) }
          })
        : _vm._e(),
      !_vm.validDate
        ? _c("gov-error-message", {
            attrs: { for: _vm.id },
            domProps: { textContent: _vm._s("Invalid date") }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }