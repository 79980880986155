var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-form-group",
    { attrs: { invalid: _vm.error !== null } },
    [
      _c(
        "gov-radios",
        [
          _c("gov-label", { attrs: { for: _vm.id } }, [
            _vm._v(
              "\n      Is this " +
                _vm._s(_vm.type) +
                " open or closed during these holiday hours?\n    "
            )
          ]),
          _c("gov-radio", {
            attrs: {
              "bind-to": _vm.value,
              id: _vm.id + "_open",
              name: _vm.id,
              label: "Open",
              value: false
            },
            on: {
              input: function($event) {
                return _vm.$emit("input", $event)
              }
            }
          }),
          _c("gov-radio", {
            attrs: {
              "bind-to": _vm.value,
              id: _vm.id + "_closed",
              name: _vm.id,
              label: "Closed",
              value: true
            },
            on: {
              input: function($event) {
                return _vm.$emit("input", $event)
              }
            }
          })
        ],
        1
      ),
      _vm.error
        ? _c("gov-error-message", {
            attrs: { for: _vm.id },
            domProps: { textContent: _vm._s(_vm.error) }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }