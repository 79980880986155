var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-form-group",
    { attrs: { invalid: _vm.error !== null } },
    [
      _c("gov-label", [
        _vm._v(_vm._s(_vm.opens_at_label) + " / " + _vm._s(_vm.closes_at_label))
      ]),
      _c("gov-select", {
        staticClass: "govuk-!-width-one-quarter",
        attrs: {
          value: _vm.opens_at,
          id: _vm.id + ".opens_at",
          name: _vm.id + ".opens_at",
          options: _vm.hours
        },
        on: {
          input: function($event) {
            return _vm.$emit("update:opens_at", $event)
          }
        }
      }),
      _vm._v(" "),
      _c("gov-select", {
        staticClass: "govuk-!-width-one-quarter",
        attrs: {
          value: _vm.closes_at,
          id: _vm.id + ".closes_at",
          name: _vm.id + ".closes_at",
          options: _vm.hours
        },
        on: {
          input: function($event) {
            return _vm.$emit("update:closes_at", $event)
          }
        }
      }),
      _vm.error
        ? _c("gov-error-message", {
            attrs: { for: _vm.id + ".opens_at" },
            domProps: { textContent: _vm._s(_vm.error) }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }